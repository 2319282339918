<template>
  <v-container fluid>
    <v-row dense>
      <v-col>
        <event-calendar-header></event-calendar-header>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="3">
        <event-calendar-filter></event-calendar-filter>
      </v-col>
      <v-col cols="9">
        <event-calendar></event-calendar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const EventCalendarHeader = () =>
  import("../event_calendar/EventCalendarHeader.vue");
const EventCalendarFilter = () =>
  import("../event_calendar/EventCalendarFilter.vue");
const EventCalendar = () => import("../event_calendar/EventCalendar.vue");

export default {
  components: {
    EventCalendarHeader,
    EventCalendarFilter,
    EventCalendar
  }
};
</script>
